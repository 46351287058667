import { useEffect, useRef } from "react";

// rellax
import Rellax from "rellax";
// typed-js
import * as Typed from "typed.js";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/bg-about-us.jpg";

import { useTranslation } from "react-i18next";
import { TypewriterEffectSmooth } from "components/ui/typewritter-effect";
import { useList } from "react-use";

function HeaderAnimated() {
  const headerRef = useRef(null);
  const typedJSRef = useRef(null);

  const { t } = useTranslation();

  // "app_mission": "Our company mission it to     ",
  // "app_mission_0": "provide solutions",
  // "app_mission_1": "get customer feedback",
  const [strings_data, { set, push }] = useList([
    { text: "We build beautiful", className: "text-white-500 dark:text-white-500" },
    // { text: t("app_mission_1") },
    // { text: t("app_mission_2") },
    { text: " & functional applications ", className: "text-blue-500 dark:text-blue-500" },
  ]);

  // Setting up typedJS
  // useEffect(() => {
  //   const typedJS = new Typed(typedJSRef.current, {
  //     strings: [t("app_mission_0"), t("app_mission_1"), t("app_mission_2"), t("app_mission_3")],
  //     typeSpeed: 70,
  //     backSpeed: 70,
  //     backDelay: 200,
  //     startDelay: 500,
  //     loop: true,
  //   });
  //   return () => typedJS.destroy();
  // }, []);

  // Setting up rellax
  useEffect(() => {
    const parallax = new Rellax(headerRef.current, {
      speed: -6,
    });

    return () => parallax.destroy();
  }, []);

  return (
    <MKBox component="header" position="relative">

      <MKBox
        ref={headerRef}
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            // container
            // item
            // xs={12}
            // lg={8}
            maxWidth={700}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            mx="auto"
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {/* {t("app_mission")} <br /> <span ref={typedJSRef} /> */}
              <div className="flex flex-col items-center justify-center h-[40rem]  ">
                <p className="text-neutral-600 dark:text-neutral-200 text-xs sm:text-base  ">
                  {/* The road to freedom starts from here */}
                </p>
                <TypewriterEffectSmooth words={strings_data} />

                <MKTypography variant="body1" color="white" mt={1} mb={6} px={{ xs: 3, lg: 6 }}>
                  {t("app_mission_subtitle")}
                </MKTypography>
              </div>
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default HeaderAnimated;
