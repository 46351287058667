import React from "react";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import bgImage from "assets/images/bg-presentation.jpg";

export const AppBrand = ({ brand, light }) => {
  const isImageBrand = typeof brand === "object" && brand.url;

  return isImageBrand ? (
    <MKBox
      minHeight="75px"
      minWidth="100px"
      //   width="100%"

      sx={{
        backgroundImage: `url(${bgImage})`,
        // backgroundColor: 'red',
        backgroundSize: "cover",
        backgroundPosition: "top",
        display: "grid",
        placeItems: "center",
      }}
    />
  ) : (
    <MKTypography variant="button" fontWeight="bold" color={light ? "white" : "dark"}>
      {brand}
    </MKTypography>
  );
};
