
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// About Us page sections
import Information from "pages/Company/AboutUs/sections/Information";
import Newsletter from "pages/Company/AboutUs/sections/Newsletter";

// Routes

// Images
import HomeFooter from "../components/footers/homefooter";
import homeNavbarRoutes from "../homeNavbarRoutes";
import DefaultNavbar from "sections/Navbar";

function AboutUsTintang() {


  return (
    <>
      <DefaultNavbar
        routes={homeNavbarRoutes}
        transparent
        dark
      />

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          // mt: -8,
          // mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        {/* <Team /> */}
        {/* <Featuring /> */}
        <Newsletter />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <HomeFooter />
      </MKBox>
    </>
  );
}

export default AboutUsTintang;
