
import { CompanyInfo, SocialMediaType, TeamInfo } from "types";

const team: TeamInfo = {
  members: [
    {
      name: "Adderly",
      job: "Developer",
      description: "Full stack & mobile developer, engages with new technologies to provide efficient and addecuate software.",
      photoUrl: "http://localhost:3000/material-kit-pro-react/static/media/team-5.d59ab7b9f95f1169cf83.jpg"
    },
     {
      name: "Adderly",
      job: "Developer",
      description: "Full stack & mobile developer, engages with new technologies to provide efficient and addecuate software.",
      photoUrl: "http://localhost:3000/material-kit-pro-react/static/media/team-5.d59ab7b9f95f1169cf83.jpg"
    },
  ]
}

const companyInfo: CompanyInfo= {
    url: process.env.REACT_APP_DOMAIN,
    name: "TintangStudios",
    socials: [
      {
        type: SocialMediaType.FB,
        url: "https://www.facebook.com/"
      },
      {
        type: SocialMediaType.TW,
        url: "https://twitter.com/"
      },
      {
        type: SocialMediaType.IG,
        url: "https://www.instagram.com/"
      },
      {
        type: SocialMediaType.PT,
        url: "https://ro.pinterest.com"
      },
      {
        type: SocialMediaType.GH,
        url: "https://github.com"
      },
    ]
} 


export const AppConfig = {
  appName: "TintangStudios",
  appTitle: "TinTang Studios",
  appLogo: "logo.jpg",
  teamInfo: team,
  companyInfo: companyInfo,
};
