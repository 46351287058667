

/** 
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Pages
import SingleArticle from "layouts/pages/blogs/single-article";
import HomeIndex from ".";
import BlogsListing from "./sections/Blogs";
import TContactUs from "./TContactUs";
import AppPrivacy from "./sections/AppPrivacy";
import AppTerms from "./sections/AppTerms";
import Showcase from "./Showcase";
import AboutUsTintang from "./AboutUsTintang";
import Blogging from "./Blogging";

// Account



// Sections

const homeRoutes = [
  {
    name: "pages",
    icon: <Icon>dashboard</Icon>,
    columns: 3,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "home",
        collapse: [
          {
            name: "home",
            route: "/home",
            component: <HomeIndex />,
          },
          {
            name: "showcase",
            route: "/pages/showcase",
            component: <Showcase />,
          },
          {
            name: "pinme",
            route: "/pages/products/pinme",
            component: <Showcase />,
          },
          {
            name: "riscoli",
            route: "/pages/products/riscoli",
            component: <Showcase />,
          },
        ],
      },
      {
        name: "company",
        collapse: [
          {
            name: "about us",
            route: "/home/about-us",
            component: <AboutUsTintang />,
          },
          {
            name: "privacy",
            route: "/home/privacy",
            component: <AppPrivacy />,
          },
          {
            name: "terms",
            route: "/home/terms-of-service",
            component: <AppTerms />,
          },
          {
            name: "contact us",
            route: "/home/contact-us",
            component: <TContactUs />,
          },
        ],
      },
      {
        name: "blogs",
        collapse: [
          {
            name: "blog",
            route: "/home/blogs",
            component: <Blogging />,
          },
          {
            name: "blog",
            route: "/home/blogs/single-article",
            component: <SingleArticle />,
          },
        ],
      },
    ],
  },

];

export default homeRoutes;
