

import Icon from "@mui/material/Icon";

const homeNavbarRoutes = [
  // {
  //   name: "pages",
  //   icon: <Icon>dashboard</Icon>,
  //   columns: 0,
  //   rowsPerColumn: 0,

  //   route: "/pages/landing-pages/rental",
  //   component: <Rental />,
  // },
  {
    name: "Showcase",
    icon: <Icon>topic</Icon>,
    columns: 0,
    rowsPerColumn: 0,

    route: "/pages/showcase",
  },

  {
    name: "products",
    icon: <Icon>category</Icon>,
    collapse: [
      {
        name: "Pin.me",
        description: "App for storing social profiles",
        href: "/pages/products/pinme",
      },
      {
        name: "Riscoli",
        description: "An easy to use and simple crm",
        href: "/pages/products/riscoli",
      },
    ],
  },
]

export default homeNavbarRoutes;