import routesDev from "routesDev";
import routesProd from "routesProd";




let routes = [] 
if(process.env.NODE_ENV != "production") {
  routes = routesDev
} else {
routes = routesProd
}

export default routes;
