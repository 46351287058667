
import Card from "@mui/material/Card";

import AppNavbar from "sections/Navbar";

import ServicesProvided from "./services_provided";
import FeaturesOfSolutions from "./featuresofsolutions";
import PeopleOfTintang from "./peopleoftintang";
import HomeFooter from "./components/footers/homefooter";

// Images
import HeaderAnimated from "./headeranimated";
import homeNavbarRoutes from "./homeNavbarRoutes";
import homeRoutes from "./homeroutes";
import routes from "routes";
import TintangProjects from "./Showcase/sections/TintangProjects";

// https://ui.aceternity.com/components/typewriter-effect
// https://www.framer.com/motion/

function HomeIndex() {
  return (
    <>
      <AppNavbar
        // routes={routes}
        // routes={homeRoutes}
        routes={homeNavbarRoutes}
        // action={{
        //   type: "external",
        //   route: "https://www.creative-tim.com/product/material-kit-pro-react",
        //   label: "buy now",
        //   color: "info",
        // }}
        transparent
        light
      />
      <HeaderAnimated />

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -4,
          mb: 2,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <FeaturesOfSolutions />
      </Card>

      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          // mt: -4,
          mb: 2,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <ServicesProvided />
      </Card>

      {/* <Card
        sx={{
          p: 2,
          mx: { xs: 1, lg: 2 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      > */}
      <TintangProjects/>
      {/* <PeopleOfTintang /> */}
      {/* </Card> */}
      <HomeFooter />
    </>
  );
}

export default HomeIndex;
