// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import GitHubIcon from "@mui/icons-material/GitHub";

// Material Kit 2 PRO React examples
import { AppConfig } from "AppConfig";
import { getSocialFromConfig } from "utils/utils";
import CenteredFooter from "../CenteredFooter";


function HomeFooter() {
  const rootUrl =  AppConfig.companyInfo.url; 

  const company = { href: rootUrl, name: AppConfig.appName };
  const links = [
    { href: rootUrl, name: "Company" },
    { href: "/home/about-us", name: "About Us" },
    { href: "/home/privacy", name: "Privacy" },
    { href: "/home/terms-of-service", name: "Terms and conditions" },
    { href: "/home/blogs", name: "Blog" },
  ];

  const socials = getSocialFromConfig(AppConfig.companyInfo.socials)

  return <CenteredFooter company={company} links={links} socials={socials} />;
}

export default HomeFooter;