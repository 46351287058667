
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// About Us page sections
import Information from "pages/Company/AboutUs/sections/Information";
import Newsletter from "pages/Company/AboutUs/sections/Newsletter";

// Routes

// Images
import HomeFooter from "../components/footers/homefooter";
import homeNavbarRoutes from "../homeNavbarRoutes";
import DefaultNavbar from "sections/Navbar";
import BlogsListing from "../sections/Blogs";
import WIP from "../WIP";
import { Container } from "@mui/material";

function Blogging() {
  return (
    <>
      <DefaultNavbar
        routes={homeNavbarRoutes}
        transparent
        dark
      />

      <MKBox
      component="section"
        // minHeight="100%"
        width="100%"
        sx={{
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Card
            sx={{
              p: 15,
              mx: { xs: 2, lg: 3 },
              mt: 10,
              mb: 4,
              boxShadoww: ({ boxShadows: { xxl } }) => xxl,
            }}
          >
            <WIP />
            {/* <Information />
        <BlogsListing /> */}
          </Card>
        </Container>
      <HomeFooter />
      </MKBox>
      {/* <MKBox pt={6} px={1} mt={6}> */}
      {/* </MKBox> */}
    </>
  );
}

export default Blogging;
