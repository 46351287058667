
export type TeamMember =  {
    name: string,
    job: string,
    description: string,
    photoUrl: string,
}

export type TeamInfo = {
    members: TeamMember[]
}  


export enum SocialMediaType {
    FB, IG, TW, GH, PT
}

export type SocialMedia = {
    type: SocialMediaType,
    url: string | undefined,
};

export type CompanyInfo = {
    socials: SocialMedia[],
    name: string,
    url: string | undefined,
}



export type SocialMediaUI =  {
    link: string | undefined,
    icon: JSX.Element | undefined
}



