

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinterestIcon from "@mui/icons-material/Pinterest";
import GitHubIcon from "@mui/icons-material/GitHub";
import { SocialMedia, SocialMediaType, SocialMediaUI } from "types";

const getSocialIconByType = (t: SocialMediaType): JSX.Element | undefined => {
  switch (t) {
    case SocialMediaType.FB:
      return <FacebookIcon fontSize="small" />;
    case SocialMediaType.TW:
      return <TwitterIcon fontSize="small" />;
    case SocialMediaType.IG:
      return <InstagramIcon fontSize="small" />;
    case SocialMediaType.PT:
      return <PinterestIcon fontSize="small" />;
    case SocialMediaType.GH:
      return <GitHubIcon fontSize="small" />;
  }
};

export const getSocialFromConfig = (socials: SocialMedia[]): SocialMediaUI[] => {
  return socials.map((e) => {
    return {
      link: e.url,
      icon: getSocialIconByType(e.type),
    };
  });
};
