export default {
    "contact_us": "contact us",
    "app_mission": "Our company mission it to     ",
    "app_mission_0": "provide solutions",
    "app_mission_1": "get customer feedback",
    "app_mission_2": "provide assistance with problems",
    "app_mission_3": "make ideas viables",
    "app_mission_subtitle": "The time to update your tools and services is now",
    "app_copyright": "Copyright © 2023 ",
    "copyright": "Copyright",
}